import React from 'react';
import { message } from 'antd';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { getPageInfo, delIp, getIpInfoDetail, addIpWhiteInfo, modifyIpWhiteInfo, getCompanyInfoListData,getProductInfoListData } from './webApi'

const initialState = {
  searchForm: fromJS({
    name: '',
    pageNum:0,
    pageSize:10,
    ip:null
  }),
  pageDataInfo:fromJS({
    pageDataList:null,
    total:null,
    size:null,
    number:null
  }),
  visibleType:false,
  eventType:null,
  ipWhiteDataInfo:fromJS({
    environment:0
  }),
  companyInfoListData:[],
  productDataList:[]
};
 
const actions = {
  setSearchForm: (store, value) => {
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set(value.get('key'),value.get('value'))});
  },
  resetSearchForm: (store) => {
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set('name','')
        .set('pageNum',0)
        .set('pageSize',10)
        .set('ip','')});
  },
  pageInfo:async (store,value)=>{
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    const companyName = searchForm.get('name');
    const ip = searchForm.get('ip');
    if(value&&value.pageNum){
      pageNum = value.pageNum
    }
    if(value&&value.pageSize){
      pageSize = value.pageSize
    }
    const {code,context} = await getPageInfo({'pageNum':pageNum,'pageSize':pageSize,'companyName':companyName,'ip':ip});
    if(code==="K-000000"){
      const pageDataList = context.ipWhiteVOPage.content;
      const total = context.ipWhiteVOPage.total;
      const size = context.ipWhiteVOPage.size;
      const number = context.ipWhiteVOPage.number+1;
      const {pageDataInfo} = store.state;
      store.setState({pageDataInfo: pageDataInfo.set('pageDataList',pageDataList)
          .set('total',total)
          .set('size',size)
          .set('number',number)});
    } else {
      message.error('查询列表出错！');
    }
  },
  getCompanyInfoListData:async(store,companyName)=>{
    const  { code, context } = await getCompanyInfoListData({'companyName':companyName});
    if(code==="K-000000"){
      store.setState({companyInfoListData: context.companyInfoVOList});
    } else {
      message.error('查询客户列表失败');
    }
  },
  getProductDataList: async (store, productName) => {
    const {code, context} = await getProductInfoListData({'productName': productName});
    if (code === "K-000000") {
      const productDataInfoList = context.productVOPage.content;
      store.setState({productDataList: productDataInfoList});
    } else {
      message.error('查询列表出错！');
    }
  },
  modalShow:async (store,eventType,id) =>{
    store.actions.changeEventType(eventType);
    store.actions.getCompanyInfoListData();
    store.actions.getProductDataList();
    store.setState({'ipWhiteDataInfo':fromJS({})});
    if(eventType===2){
      store.actions.ipInfoData(id)
    }
    store.setState({visibleType:true});
  },
  modalHidden:async (store) =>{
    store.setState({visibleType:false});
  },
  changeEventType:async(store,value)=>{
    store.setState({eventType:value});
  },
  onDelIp:async (store,id)=>{
    const {code} = await delIp({'id':id});
    if(code==="K-000000"){
      message.success('删除成功！');
      store.actions.pageInfo()
    } else {
      message.error('删除失败！');
    }
  },
  ipInfoData:async(store,id)=>{
    const {code,context} =  await getIpInfoDetail({'id':id});
    if(code==="K-000000"){
      const ipWhiteInfo = context.ipWhiteVO;
      store.setState({'ipWhiteDataInfo':fromJS(ipWhiteInfo)});
    } else {
      message.error('查询失败！');
    }
  },
  changeIpInfoData:async(store,value)=>{
    const {ipWhiteDataInfo} = store.state;
    store.setState({'ipWhiteDataInfo':ipWhiteDataInfo.set(value.get('key'),value.get('value'))});
  },
  addIpWhiteInfo:async(store)=>{
    const {ipWhiteDataInfo,eventType} = store.state;
    const {code} = eventType===1?await addIpWhiteInfo(ipWhiteDataInfo.toJS()):await modifyIpWhiteInfo(ipWhiteDataInfo.toJS());
    if(code==="K-000000"){
      message.success('保存成功！');
      store.actions.modalHidden();
      store.actions.pageInfo();
    } else if(code==="K-000002"){
      message.error('ip地址已存在！');
    } else {
      message.error('保存失败');
    }
  }
};

export default globalHook(React, initialState, actions);