import React, {Fragment, useEffect} from 'react';
import Breadcrumb from './components/breadcrumb';
import Search from './components/search';
import List from './components/list';
import DomainInfoModal from './components/domain-info-modal'
import useGlobal from './store';

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const {pageInfo, resetForm} = globalActions;
    resetForm();
    pageInfo();
  }, [globalActions]);
  return (
      <Fragment>
        <Breadcrumb/>
        <Search
            setSearchForm={globalActions.setSearchForm}
            searchClick={globalActions.pageInfo}
            searchForm={globalState.searchForm}
            modalShow={globalActions.modalShow}
        />
        <List
            pageDataInfo={globalState.pageDataInfo}
            changePageInfo={globalActions.pageInfo}
            onDelDomainGrant={globalActions.onDelDomainGrant}
            modalShow={globalActions.modalShow}
        />
        <DomainInfoModal
            visibleType={globalState.visibleType}
            modalHidden={globalActions.modalHidden}
            eventType={globalState.eventType}
            domainGrantDataInfo={globalState.domainGrantDataInfo}
            changeDomainGrantInfoData={globalActions.changeDomainGrantInfoData}
            addDomainGrant={globalActions.addDomainGrant}
            companyInfoListData={globalState.companyInfoListData}
            getCompanyInfoListData={globalActions.getCompanyInfoListData}
            productListData={globalState.productListData}
            getProductInfoList={globalActions.getProductInfoList}
        />
      </Fragment>
  )
}
