import React, {Fragment, useEffect} from 'react';
import ProjectBreadcrumb from './components/project-breadcrumb';
import ProjectList from './components/project-list';
import ProjectSearch from './components/project-search';
import useGlobal from './store';
import ProjectModal from './components/project-modal'

export default () => {
    const [globalState, globalActions] = useGlobal();
    const {setPageInfo, setSearchForm, deleteOperator, setVisible, setRowInfo, saveOperator, resetForm, getCustomerList} = globalActions;
    const {pageInfo, searchForm, visible, rowInfo, customerList} = globalState;
    useEffect(() => {
        resetForm();
        setPageInfo();
    }, [setPageInfo, resetForm])
    return (
        <Fragment>
            <ProjectBreadcrumb/>
            <ProjectSearch
                setSearchForm={setSearchForm}
                setPageInfo={setPageInfo}
                searchForm={searchForm}
                setVisible={setVisible}
            />
            <ProjectList
                pageInfo={pageInfo}
                searchForm={searchForm}
                setPageInfo={setPageInfo}
                setSearchForm={setSearchForm}
                deleteOperator={deleteOperator}
                setRowInfo={setRowInfo}
                setVisible={setVisible}
            />
            <ProjectModal
                setVisible={setVisible}
                visible={visible}
                rowInfo={rowInfo}
                setRowInfo={setRowInfo}
                saveOperator={saveOperator}
                customerList={customerList}
                getCustomerList={getCustomerList}
            />
        </Fragment>
    )
}
