import React,{ Fragment} from 'react';
import Nav from './system-nav';
import './App.css';
import { Layout ,Dropdown,Icon,Menu} from 'antd';
import {  Route,Switch,Link  } from 'react-router-dom';
import routers from "./router";
const { Content,Header,Sider } = Layout;



const App = () => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/login" onClick={() => {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('name');
        }}>
          登出
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Fragment>
      <Layout>
      <Header className="header">
        <div className="logo" style={{float: 'left'}}>
          <img alt="" src="https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/201911301605007635.ico" />
          <span>&nbsp;&nbsp;万米LICENSE授权平台</span>
        </div>
        <div className="dropdown" style={{float: 'right'}}>
        <Dropdown overlay={menu} >
          <Link to="" className="ant-dropdown-link" style={{color: '#fff'}}  onClick={e => e.preventDefault()}>
            {sessionStorage.getItem('name')} <Icon type="down" />
          </Link>
        </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider width={200} style={{ background: '#fff' }}>
            <Nav />
        </Sider>
        <Layout style={{ paddingLeft: 12 }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              height: 'calc(100vh - 64px)'
            }}
          >
            <div style={{ textAlign: 'center' }}>
                <Switch>
                    {routers.map(router => <Route  key={router.path}  exact={router.exact} path= {router.path} component={router.component}/>)}
                </Switch>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Fragment>
  )
}

export default App;
