import React from "react";
import {Form, Input, Modal} from 'antd';
import {fromJS} from "immutable";

const FormItem = Form.Item;

const productForm = (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: {span: 24},
      sm: {span: 6}
    },
    wrapperCol: {
      span: 24,
      xs: {span: 24},
      sm: {span: 14}
    }
  };
  const {getFieldDecorator} = props.form;
  const {visibleType, modalHidden, eventType, productDataInfo, changeProductInfoData, addProductInfo} = props;
  return (
      <Modal maskClosable={false}
             title={eventType === 1 ? '新增' : "编辑"}
             width={600}
             visible={visibleType}
             okText='确定'
             cancelText='取消'
             onCancel={() => {
               props.form.resetFields();
               modalHidden()
             }
             }
             onOk={() => {
               props.form.validateFields(null, (errs) => {
                 if (!errs) {
                   addProductInfo()
                   props.form.resetFields();
                 }
               });
             }
             }
      >
        <Form {...formItemLayout}>
          <FormItem label="产品名称" hasFeedback>
            {getFieldDecorator('productName', {
              initialValue: productDataInfo.get('productName'),
              rules: [{required: true, message: '请输入产品名称'}],
            })(
                <Input placeholder="请输入产品名称" onChange={(e) => {
                  changeProductInfoData(fromJS({'key': 'productName', 'value': e.target.value}))
                }}
                />
            )}
          </FormItem>
        </Form>
      </Modal>
  )
}

const form = Form.create({name: 'productForm'})(productForm);
export default form;


