import React from "react";
import {Table, Button} from 'antd';
import secondCheck from '../../seondCheck'

export default ((props) => {
    const {pageInfo, setPageInfo, setSearchForm, deleteOperator, setRowInfo, setVisible} = props;
    const columns = [
        {
            key: 'companyName',
            dataIndex: 'companyName',
            title: '项目名称'
        },
        {
            key: 'customerName',
            dataIndex: 'customerName',
            title: '客户名称'
        },
        {
            key: 'email',
            dataIndex: 'email',
            title: '邮箱地址'
        },
        {
            key: 'remark',
            dataIndex: 'remark',
            title: '备注'
        },
        {
            key: 'createTime',
            dataIndex: 'createTime',
            title: '创建时间'
        },
        {
            key: 'option',
            title: '操作',
            render: (rowInfo) => {
                return (
                    <div>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                setRowInfo(rowInfo);
                                setVisible();
                            }}
                        >
                            编辑
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                secondCheck({okCallBack: deleteOperator, okParam: rowInfo.id})
                            }}>删除</Button>
                    </div>
                )
            }
        }];
    return (
        <Table
            rowKey={record => record.id}
            columns={columns}
            dataSource={pageInfo && pageInfo.toJS().content}
            pagination={{
                pageSize: pageInfo.get('size'),
                total: pageInfo.get('totalElements'),
                current: pageInfo.get('number') + 1,
                onChange: (currentPage) => {
                    setSearchForm({key: 'pageNum', value: currentPage - 1});
                    setPageInfo();
                }
            }}
        />
    )
})