import React from "react";
import {Form, Input, Button, Select} from 'antd';
import {fromJS} from "immutable";
const FormItem = Form.Item;
const Option = Select.Option;


export default ({setSearchForm,searchClick,searchForm}) => {
  return (
      <Form layout="inline"  style={{textAlign: 'left',paddingTop:10,paddingBottom:10}}>
        <FormItem label="项目名称">
          <Input
            placeholder="请输入项目名称"
            value={searchForm.get('name')}
            onChange={(e) => setSearchForm(fromJS({key:'name',value:e.target.value}))}
            />
        </FormItem>
        <FormItem label="域名/mac地址">
          <Input
            placeholder="请输入域名/mac地址"
            value={searchForm.get('domainMac')}
            onChange={(e) => setSearchForm(fromJS({key:'domainMac',value:e.target.value}))}
          />
        </FormItem>
        <FormItem label="告警类型">
          <Select
            defaultValue="所有"
            onChange={(v) => {
              setSearchForm(fromJS({key:'warnType',value: v}));
            }}
            style={{ width: 180 }}
          >
            <Option key="-1" value={null}>所有</Option>
            <Option key="0" value={0}>非法授权告警</Option>
            <Option key="1" value={1}>证书不在有效期内告警</Option>
          </Select>
        </FormItem>
        <FormItem label="校验类型">
          <Select
            defaultValue="所有"
            onChange={(v) => {
              setSearchForm(fromJS({key:'type',value: v}));
            }}
          >
            <Option key="-1" value={null}>所有</Option>
            <Option key="0" value={0}>前端</Option>
            <Option key="1" value={1}>后端</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => searchClick()}>查询</Button>
        </FormItem>
      </Form>
  )
}


