import React from "react";
import {Button, Form, Input,Tooltip,Icon,Row,Col} from 'antd';
import {fromJS} from "immutable";

const FormItem = Form.Item;

const ipForm = (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: { span: 12 },
      sm: { span: 6 }
    },
    wrapperCol: {
      span: 12,
      xs: { span: 16 },
      sm: { span: 14 }
    }
  };
  const { getFieldDecorator } = props.form;
  const {warnEmailConfigData,changeEmailConfigInfo,saveEmailConfigInfo} = props;
  return (
    <div style={{paddingTop:20}}>
    <Form {...formItemLayout}>
        <Row>
            <Col span={16}>
              <FormItem label="接收邮箱地址" hasFeedback>
                {getFieldDecorator('address', {
                  initialValue:warnEmailConfigData.get('address'),
                  rules: [{ required: true, message: '请输入接收邮箱地址' }],
                })(
                  <Input onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'address','value':e.target.value}))}}
                         placeholder="请输入接收邮箱地址,多个用英文逗号隔开"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <div style={{
                  marginLeft: '-30%',
                  height: 40,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
              }}>
                  <Tooltip placement="right" title="接收邮箱地址多个,用英文逗号隔开" visible={true} onVisibleChange={(visible) => visible = true}>
                      <Icon style={{ color: '#999' }} type="exclamation-circle" />
                  </Tooltip>
              </div>
            </Col>
        {/*<div style={{textAlign:'left',color:'red',height:10}}>*/}
          {/*<span>接收邮箱地址多个，请用英文逗号隔开</span>*/}
        {/*</div>*/}
      </Row>
      <Row>
        <Col span={16}>
          <FormItem label="发送邮件地址" hasFeedback>
            {getFieldDecorator('smtpAddress', {
              initialValue: warnEmailConfigData.get('smtpAddress'),
              rules: [{ required: true, message: '请输入发送邮件地址' }],
            })(
              <Input
                onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'smtpAddress','value':e.target.value}))}}
              />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormItem label="smtp地址" hasFeedback>
            {getFieldDecorator('smtpHost', {
              initialValue:warnEmailConfigData.get('smtpHost'),
              rules: [{ required: true, message: '请输入smtp地址' }],
            })(
              <Input onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'smtpHost','value':e.target.value}))}}
              /> 
            )}
          </FormItem >
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormItem label="smtp端口" hasFeedback>
            {getFieldDecorator('smtpPort', {
              initialValue: warnEmailConfigData.get('smtpPort'),
              rules: [{ required: true, message: '请输入smtp端口' }],
            })(
              <Input
                onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'smtpPort','value':e.target.value}))}}
              />
            )}
          </FormItem>
          </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormItem label="密码" hasFeedback>
            {getFieldDecorator('password', {
              initialValue: warnEmailConfigData.get('password'),
              rules: [{ required: true, message: '请输入密码' }],
            })(
              <Input
                type="password"
                onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'password','value':e.target.value}))}}
              />
            )}
          </FormItem>
          </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormItem label="到期提醒时间(天)" hasFeedback>
            {getFieldDecorator('warnDays', {
              initialValue: warnEmailConfigData.get('warnDays'),
              rules: [{ required: true, message: '请输入到期提醒时间' }],
            })(
                <Input
                    onChange={(e)=>{changeEmailConfigInfo(fromJS({'key':'warnDays','value':e.target.value}))}}
                />
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormItem>
            <Button type="primary" onClick={() => saveEmailConfigInfo()}>保存</Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
    </div>
  )
}

const form = Form.create({ name: 'ipForm' })(ipForm);
export default form;


