import { Breadcrumb } from 'antd';
import React from "react";

export default (() => {
  return (
    <Breadcrumb style={{textAlign: 'left'}}>
      <Breadcrumb.Item>证书列表</Breadcrumb.Item>
    </Breadcrumb>
  )
})
