import React, { Fragment,useEffect }  from 'react';
import Breadcrumb from './components/breadcrumb';
import Search from './components/search';
import List from './components/list';
import useGlobal from './store';
import CheckLogModal from './components/checklog-info-modal'

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const { pageInfo,resetForm } = globalActions;
    resetForm();
    pageInfo();
  }, [globalActions]);
  return (
    <Fragment>
      <Breadcrumb />
      <Search
        setSearchForm= {globalActions.setSearchForm}
        searchClick= {globalActions.pageInfo}
        searchForm = {globalState.searchForm}
        modalShow = {globalActions.modalShow}
      />
      <List
        pageDataInfo = {globalState.pageDataInfo}
        changePageInfo= {globalActions.pageInfo}
        onDelCheckLog = {globalActions.onDelCheckLog}
        modalShow = {globalActions.modalShow}/>
      <CheckLogModal
          visibleType = {globalState.visibleType}
          modalHidden = {globalActions.modalHidden}
          eventType = {globalState.eventType}
          checkLogDataInfo = {globalState.checkLogDataInfo}
      />
    </Fragment>
  )
}
