import React from "react";
import {Form, Input, Modal, Select} from 'antd';
import {fromJS} from "immutable";

const FormItem = Form.Item;
const {TextArea} = Input;
const Option = Select.Option;
const customerForm = (props) => {
    const formItemLayout = {
        labelCol: {
            span: 2,
            xs: {span: 24},
            sm: {span: 6}
        },
        wrapperCol: {
            span: 24,
            xs: {span: 24},
            sm: {span: 14}
        }
    };
    const {getFieldDecorator} = props.form;
    const {rowInfo, setRowInfo, saveOperator, customerList, getCustomerList} = props;
    console.log('customerList====>', customerList.toJS())
    console.log('rowInfo====>', rowInfo)
    return (
        <Modal
            maskClosable={false}
            title={rowInfo ? '编辑' : '新增'}
            width={600}
            visible={props.visible}
            cancelText="取消"
            okText="确定"
            onCancel={() => {
                props.form.resetFields();
                setRowInfo(null);
                props.setVisible();
            }}
            onOk={() => {
                props.form.validateFields(null, (errs, values) => {
                    if (!errs) {
                        saveOperator(values);
                        props.form.resetFields();
                        setRowInfo(null);
                        props.setVisible();
                    }
                });
            }}
        >
            <Form {...formItemLayout}>
                <FormItem label="项目名称" hasFeedback>
                    {getFieldDecorator('companyName', {
                        initialValue: rowInfo && rowInfo.get('companyName'),
                        rules: [{required: true, message: '请输入项目名称'}],
                    })(
                        <Input
                            placeholder="请输入项目名称"
                        />
                    )}
                </FormItem>
                <FormItem label="邮箱地址" hasFeedback>
                    {getFieldDecorator('email', {
                        initialValue: rowInfo && rowInfo.get('email'),
                        rules: [{type: 'email', required: true, message: '请输入正确的邮箱地址'}],
                    })(
                        <Input
                            placeholder="请输入邮箱地址"
                        />
                    )}
                </FormItem>
                <FormItem label="客户" hasFeedback>
                    {getFieldDecorator('customerId', {
                        initialValue: rowInfo && rowInfo.get('customerId'),
                        rules: [{required: true, message: '请选择客户'}],
                    })(
                        <Select placeholder="请选择客户"
                                showSearch
                                onSearch={(value) => {
                                    getCustomerList(value)
                                }}
                        >
                            {customerList.map(customer =>
                                <Option key={customer.get('id')}
                                        value={customer.get('id')}>{customer.get('customerName')}</Option>
                            )}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="备注" hasFeedback>
                    {getFieldDecorator('remark', {
                        initialValue: rowInfo && rowInfo.get('remark')
                    })(
                        <TextArea
                            placeholder="请输入客户备注"
                        />
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}
const form = Form.create({name: 'customerForm'})(customerForm);
export default form;

