import React from "react";
import { Modal } from 'antd';
import EditFormWrapper from './edit-modal'

export default ({visibleType,modalHidden,eventType,checkLogDataInfo}) => {
  return (
    <Modal  maskClosable={false}
            title={eventType===1?'新增':"编辑"}
            width={600}
            visible={visibleType}
            okText = '确定'
            cancelText = '取消'
            onCancel={() => modalHidden()}
            // onOk={this._onOk}
    >
      <EditFormWrapper
        eventType = {eventType}
        checkLogDataInfo = {checkLogDataInfo}
        // ref={(form) => (this._form = form)}
        // relaxProps={this.props.relaxProps}
      />
    </Modal>
  )
}


