import React from "react";
import {Table} from 'antd';


export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number');
  const columns = [
    {
      key: 'opModule',
      dataIndex: 'opModule',
      title: '操作模块',
      width:'15%'
    },
    {
      key: 'opCode',
      dataIndex: 'opCode',
      title: '操作类型'
    },
    {
      key: 'opContext',
      title: '操作内容',
      render: (rowInfo) => {
        if(rowInfo.opCode.indexOf('删除') > -1){
          return (rowInfo.opCode + ' id:'+ rowInfo.opContext)
        }else{
          return (rowInfo.opCode + rowInfo.opContext)
        }
      },
      width: '40%'
    },
    {
      key: 'opIp',
      dataIndex: 'opIp',
      title: '操作ip'
    },
    {
      key: 'opTime',
      dataIndex: 'opTime',
      title: '操作时间'
    }];
  return (
      <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={pageDataList}
          pagination={{
            total,
            pageSize,
            current: current,
            onChange: (pageNum, pageSize) => {
              props.changePageInfo({pageNum: pageNum - 1, pageSize});
            }
          }}
      />
  )
})