import React from 'react';
import { Form, Icon, Input, Button } from 'antd';
import useGlobal from './store';
const FormItem = Form.Item;
const bg = require('../bg.jpg');


export default (props) => {
    const [, globalActions] = useGlobal();
    const { form } = props;
    const getFieldDecorator = form && form.getFieldDecorator
    return getFieldDecorator ? (
      <div style={styles.container}>
        <Form style={styles.loginForm}>
        <FormItem style={{ marginBottom: 15 }}>
          <div style={styles.header}>
            <img style={styles.logo} src="https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/201906251656157711.png" alt="" />
          </div>
          <strong style={styles.title}>LICENSE授权平台</strong>
        </FormItem>
        <FormItem>
        {getFieldDecorator('account', {
            initialValue: '',
            rules: [{ required: true, message: '请输入您的登录账号' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ fontSize: 13 }} />}
              placeholder="请输入您的登录账号"
          />
        )}
        </FormItem>
        <FormItem style={{ marginBottom: 0 }}>
        {getFieldDecorator('password', {
            initialValue: '',
            rules: [{ required: true, message: '请输入您的密码' }],
          })(
            <Input
            prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
            type="password"
            placeholder="密码"
          />
        )}
         
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            style={styles.loginBtn}
            onClick={(e) => {
              form.validateFields(null, (errs,values) => {
                if (!errs) {
                  globalActions.setToken(values)
                }
              });
            }}
          >
            登录
          </Button>
        </FormItem>
        <FormItem style={{ marginBottom: 0 }}>
          <div>
            <p
              style={{ textAlign: 'center', lineHeight: '20px', color: '#999' }}
            >
              © 2017-2020 南京万米信息技术有限公司
            </p>
            <p
              style={{ textAlign: 'center', lineHeight: '20px', color: '#999' }}
            >
              版本号：V1.0.0
            </p>
          </div>
        </FormItem>
      </Form>
      </div>
    ): null;
  }
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: 'url(' + bg + ')'
  },
  loginForm: {
    width: 370,
    minHeight: 325,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 30,
    marginTop: -50
  },
  loginBtn: {
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10
  },
  logo: {
    display: 'block',
    width: 'auto',
    height: 42
  },
  title: {
    fontSize: 18,
    color: '#333',
    lineHeight: 1,
    textAlign: 'center',
    display: 'block'
  }
};