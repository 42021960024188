import React from "react";
import {Form, Input, Modal, Select} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

const domainGrantForm = (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: {span: 24},
      sm: {span: 6}
    },
    wrapperCol: {
      span: 24,
      xs: {span: 24},
      sm: {span: 14}
    }
  };
  const {getFieldDecorator} = props.form;
  const {
    visibleType, modalHidden, eventType, domainGrantDataInfo, changeDomainGrantInfoData, addDomainGrant,
    companyInfoListData, getCompanyInfoListData, getProductInfoList, productListData
  } = props;
  return (
      <Modal maskClosable={false}
             title={eventType === 1 ? '新增' : "编辑"}
             width={600}
             visible={visibleType}
             okText='确定'
             cancelText='取消'
             onCancel={() => {
               props.form.resetFields();
               modalHidden()
             }
             }
             onOk={() => {
               props.form.validateFields(null, (errs) => {
                 if (!errs) {
                   addDomainGrant()
                   props.form.resetFields();
                 }
               });
             }
             }
      >
        <Form {...formItemLayout}>
          <FormItem label="项目名称" hasFeedback>
            {getFieldDecorator('companyId', {
              initialValue: domainGrantDataInfo.get('companyId'),
              rules: [{required: true, message: '请选择公司'}],
            })(
                <Select placeholder="请选择公司"
                        showSearch
                        filterOption = {(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
                        onSelect={(v) => {
                          changeDomainGrantInfoData({
                            'key': 'companyId',
                            'value': v
                          })
                        }}
                >
                  {companyInfoListData.map(company =>
                      <Option key={company.id} value={company.id}>{company.companyName}</Option>
                  )}
                </Select>
            )}
          </FormItem>
          <FormItem label="产品名称" hasFeedback>
            {getFieldDecorator('productId', {
              initialValue: domainGrantDataInfo.get('productId'),
              rules: [{required: true, message: '请选择产品'}],
            })(
                <Select
                    placeholder="请选择产品"
                    showSearch
                    onSearch={(value) => {
                      getProductInfoList({productName: value});
                    }}
                    onSelect={(v) => {
                      changeDomainGrantInfoData({
                        'key': 'productId',
                        'value': v
                      })
                    }
                    }
                >
                  {productListData.map(product =>
                      <Option key={product.id} value={product.id}>{product.productName}</Option>
                  )}
                </Select>
            )}
          </FormItem>
          <FormItem label="域名" hasFeedback>
            {getFieldDecorator('domain', {
              initialValue: domainGrantDataInfo.get('domain'),
              rules: [{required: true, message: '请输入域名'}],
            })(
                <Input
                    placeholder="请输入域名"
                    onChange={(e) => {
                      changeDomainGrantInfoData({'key': 'domain', 'value': e.target.value})
                    }}
                />
            )}
          </FormItem>
          <FormItem label="环境" hasFeedback>
            {getFieldDecorator('environment', {
              initialValue: domainGrantDataInfo.get('environment'),
              rules: [{required: true, message: '请选择环境'}],
            })(
                <Select
                    placeholder="请选择环境"
                    onSelect={(v) => {
                      changeDomainGrantInfoData({
                        'key': 'environment',
                        'value': v
                      })
                    }
                    }

                >
                  <Option value={0}>开发</Option>
                  <Option value={1}>测试</Option>
                  <Option value={2}>UAT</Option>
                  <Option value={3}>线上</Option>
                </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
  )
}

const form = Form.create({name: 'domainGrantForm'})(domainGrantForm);
export default form;


