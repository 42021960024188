import React from 'react';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { page,deleteById,modify,add,list,productList } from './webApi'
import { message as msg } from 'antd';
const initForm = fromJS({
  mac: null,
  domain: null,
  environment: null,
  companyName: null,
  pageNum: 0,
  pageSize: 10
})
const initialState = {
  searchForm: initForm,
  pageInfo: fromJS({}),
  rowInfo: null,
  visible: false,
  companyList: [],
  productList: []
};
 
const actions = {
  resetForm: (store) => {
    store.setState({ searchForm: initForm});
  },
  setSearchForm: (store, {key,value}) => {
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set(key,value) });
  },
  setPageInfo: async(store) => {
    const { searchForm } = store.state;
    const { context, code, message } = await page(searchForm);
    if(code==="K-000000"){
      const { certGrantInfoVOPage } = context;
      store.setState({pageInfo : fromJS(certGrantInfoVOPage)});
    } else {
      msg.error(message);
    }
  },
  deleteOperator: async(store, id) => {
    const  { code,message } = await deleteById({id});
    if(code==="K-000000"){
      msg.success("删除成功！");
      store.actions.setPageInfo();
    } else {
      msg.error(message);
    }
  },
  setRowInfo: (store, rowInfo) => {
    store.setState({rowInfo : fromJS(rowInfo)});
  },
  setVisible: (store) => {
    const { visible } = store.state;
    store.setState({visible : !visible})
  },
  saveOperator: async(store, values) => {
    const { rowInfo } = store.state;
    let code,message;
    if (rowInfo) {
      let params = rowInfo.merge(fromJS(values).remove('rangeDate')).toJS();
      const { companyList } = store.state;
      const company = companyList.find(company => company.id === params.companyId);
      params.companyName = company.companyName;
      const res = await modify(params);
      code = res.code;
      message = res.message;
    } else {
      let params = fromJS(values).remove('rangeDate').toJS();
      const { companyList } = store.state;
      const company = companyList.find(company => company.id === params.companyId);
      params.companyName = company.companyName;
      const res = await add(params);
      code = res.code;
      message = res.message;
    }
    if(code==="K-000000"){
      msg.success("编辑成功！");
      store.actions.setPageInfo();
    } else {
      msg.error(message);
    }
  },
  setCompanyList: async(store) => {
    const  { code, context, message } = await list();
    if(code==="K-000000"){
      store.setState({companyList: context.companyInfoVOList});
    } else {
      msg.error(message);
    }
  },
  setProductList: async(store) => {
    const  { code, context, message } = await productList();
    if(code==="K-000000"){
      store.setState({productList: context.productVOList});
    } else {
      msg.error(message);
    }
  },
};



export default globalHook(React, initialState, actions);