import React from "react";
import {Button, Form, Input} from 'antd';

const FormItem = Form.Item;


export default ({setSearchForm, searchClick, searchForm, modalShow}) => {
  return (
      <Form layout="inline" style={{textAlign: 'left', paddingTop: 10, paddingBottom: 10}}>
        <FormItem label="项目名称">
          <Input
              placeholder="请输入项目名称"
              value={searchForm.get('companyName')}
              onChange={(e) => setSearchForm({key: "companyName", value: e.target.value})}
          />
        </FormItem>
        <FormItem label="域名">
          <Input
              placeholder="请输入域名"
              value={searchForm.get('domain')}
              onChange={(e) => setSearchForm({key: "domain", value: e.target.value})}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => searchClick()}>查询</Button>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => modalShow(1)}>新增</Button>
        </FormItem>
      </Form>
  )
}


