import React from "react";
import {Form, Input, Button} from 'antd';

const FormItem = Form.Item;


export default ({setSearchForm, setPageInfo, searchForm, setVisible}) => {
    return (
        <Form layout="inline" style={{textAlign: 'left', paddingTop: 10, paddingBottom: 10}}>
            <FormItem label="项目名称">
                <Input
                    allowClear
                    placeholder="请输入项目名称"
                    value={searchForm.get('companyName')}
                    onChange={(e) => setSearchForm({key: 'companyName', value: e.target.value})}
                />
            </FormItem>
            <FormItem>
                <Button type="primary" onClick={() => setPageInfo()}>查询</Button>
            </FormItem>
            <FormItem>
                <Button type="primary" onClick={() => setVisible()}>新增</Button>
            </FormItem>
        </Form>
    )
}


