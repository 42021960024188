import React from 'react';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { page,deleteById,modify,add } from './webApi'
import { message as msg } from 'antd';
import moment from "moment";

const initForm = fromJS({
  customerName: null,
  customerCode: null,
  city: null,
  webSiteAddress: null,
  contractDate: null,
  pageNum: 0,
  pageSize: 10
})

const initialState = {
  searchForm: initForm,
  pageInfo: fromJS({}),
  rowInfo: null,
  visible: false,
  customerList: []
};
 
const actions = {
  resetForm: (store) => {
    store.setState({ searchForm: initForm});
  },
  setSearchForm: (store, {key,value}) => {
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set(key,value) });
  },
  setPageInfo: async(store) => {
    const { searchForm } = store.state;
    const { context, code, message } = await page(searchForm);
    if(code==="K-000000"){
      const { customerVOPage } = context;
      store.setState({pageInfo : fromJS(customerVOPage)});
    } else {
      msg.error(message);
    }
  },
  deleteOperator: async(store, id) => {
    const  { code, message } = await deleteById({id});
    if(code==="K-000000"){
      msg.success("删除成功！");
      store.actions.setPageInfo();
    } else {
      msg.error(message);
    }
  },
  setRowInfo: (store, rowInfo) => {
    store.setState({rowInfo : fromJS(rowInfo)});
  },
  setVisible: (store) => {
    const { visible } = store.state;
    store.setState({visible : !visible})
  },
  saveOperator: async(store, values) => {
    const { rowInfo } = store.state;
    let code,message;
    if (rowInfo) {
      const params = rowInfo.merge(fromJS(values)).toJS();
      params.contractDate = moment(params.contractDate).format('YYYY-MM-DD');
      const res = await modify(params);
      code = res.code;
      message = res.message;
    } else {
      const params = fromJS(values).toJS();
      params.contractDate = moment(params.contractDate).format('YYYY-MM-DD');
      const res = await add(params);
      code = res.code;
      message = res.message;
    }
    if(code==="K-000000"){
      msg.success("操作成功！");
      store.actions.setPageInfo();
    } else {
      msg.error(message);
    }
  }
};



export default globalHook(React, initialState, actions);