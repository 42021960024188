import React from "react";
import { Table, Button  } from 'antd';
import secondCheck from '../../seondCheck'
export default ((props) => {
  const { pageInfo, setPageInfo,setSearchForm,deleteOperator,setRowInfo,setVisible } = props;
  const columns = [{
    key: 'companyName',
    dataIndex: 'companyName',
    title: '项目名称'
    },
    {
      key: 'productName',
      dataIndex: 'productName',
      title: '产品名称'
    },
    {
      key: 'mac',
      dataIndex: 'mac',
      title: 'mac地址',
      width: 300
    },
    {
      key: 'domain',
      dataIndex: 'domain',
      title: '域名'
    },
    {
      key: 'environment',
      dataIndex: 'environment',
      title: '环境',
      render: (environment) => {
        let environmentValue = '无';
        if (environment === 0) {
          environmentValue = '开发';
        } else if (environment === 1) {
          environmentValue = '测试';
        } else if (environment === 2) {
          environmentValue = 'UAT';
        } else if (environment === 3) {
          environmentValue = '线上';
        }
        return environmentValue;
      }
    },
    {
      key: 'startDate',
      dataIndex: 'startDate',
      title: '生效开始时间'
    },
    {
      key: 'endDate',
      dataIndex: 'endDate',
      title: '生效结束时间'
    },
    {
      key: 'createTime',
      dataIndex: 'createTime',
      title: '创建时间'
    },
    {
      key: 'remark',
      dataIndex: 'remark',
      title: '备注'
    },
    {
      key: 'option',
      title: '操作',
      render: (rowInfo) => {return (
        <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                const fun = (certFile) => {
                  window.open(certFile)
                }
                secondCheck({
                  okCallBack: fun,
                  okParam: rowInfo.certFile
                })
              }}
            >
              下载证书
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setRowInfo(rowInfo);
                setVisible();
              }}
            >
              编辑
            </Button>
            <Button
              type="link" 
              size="small"
              onClick={() => {
                secondCheck({
                  okCallBack: deleteOperator,
                  okParam: rowInfo.id 
                })
              }}>删除</Button>
        </div>
      )}
    }];
  return (
    <Table 
      rowKey={record => record.id}
      columns={columns} 
      dataSource={pageInfo && pageInfo.toJS().content}
      pagination={{
        pageSize: pageInfo.get('size'),
        total: pageInfo.get('totalElements'),
        current: pageInfo.get('number') + 1,
        onChange: (currentPage) => {
          setSearchForm({key: 'pageNum',value: currentPage -1 });
          setPageInfo();
        }
      }}
    />
  )
})