import fly from '../fly'


export const page = (params) => {
    return fly.post("/companyInfo/page", params)
}


export const deleteById = (params) => {
    return fly.post("/companyInfo/deleteById", params)
}


export const modify = (params) => {
    return fly.post("/companyInfo/modify", params)
}

export const add = (params) => {
    return fly.post("/companyInfo/add", params)
}

export const getCustomerList = (params) => {
    return fly.post("/customer/page", params)
}