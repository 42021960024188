import React from 'react';
import globalHook from 'use-global-hook';
import {fromJS} from 'immutable';
import {
  addDomainGrant,
  companyInfoList,
  delIp,
  getIpInfoDetail,
  getPageInfo,
  modifyIpWhiteInfo,
  productInfoList
} from './webApi'
import {message as msg} from "antd";

const initForm = fromJS({
  companyName: '',
  domain: null,
  pageNum: 0,
  pageSize: 10
})
const initialState = {
  searchForm: fromJS({
    companyName: '',
    pageNum: 0,
    pageSize: 10,
    domain: null
  }),
  pageDataInfo: fromJS({
    pageDataList: null,
    total: null,
    size: null,
    number: null
  }),
  visibleType: false,
  eventType: null,
  domainGrantDataInfo: fromJS({}),
  companyInfoListData: [],
  productListData: []
};

const actions = {
  resetForm: (store) => {
    store.setState({searchForm: initForm});
  },
  setSearchForm: (store, value) => {
    const {searchForm} = store.state;
    store.setState({searchForm: searchForm.set(value.key, value.value)});
  },
  pageInfo: async (store, value) => {
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    const companyName = searchForm.get('companyName');
    const domain = searchForm.get('domain');
    if (value && value.pageNum) {
      pageNum = value.pageNum
    }
    if (value && value.pageSize) {
      pageSize = value.pageSize
    }
    const {code, context, message} = await getPageInfo({
      'pageNum': pageNum,
      'pageSize': pageSize,
      'companyName': companyName,
      'domain': domain
    });
    if (code === "K-000000") {
      const pageDataList = context.domainGrantVOPage.content;
      const total = context.domainGrantVOPage.total;
      const size = context.domainGrantVOPage.size;
      const number = context.domainGrantVOPage.number;
      const {pageDataInfo} = store.state;
      store.setState({
        pageDataInfo: pageDataInfo.set('pageDataList', pageDataList)
            .set('total', total)
            .set('size', size)
            .set('number', number)
      });
    } else {
      msg.error(message);
    }
  },
  modalShow: async (store, eventType, id) => {
    store.actions.changeEventType(eventType);
    store.setState({'domainGrantDataInfo': fromJS({})});
    if (eventType === 2) {
      store.actions.domainGrantData(id)
    }
    store.setState({visibleType: true});
    store.actions.getCompanyInfoListData({});
    store.actions.getProductInfoList({});
  },
  modalHidden: async (store) => {
    store.setState({visibleType: false});
  },
  changeEventType: async (store, value) => {
    store.setState({eventType: value});
  },
  onDelDomainGrant: async (store, id) => {
    const {code, message} = await delIp(id);
    if (code === "K-000000") {
      msg.success('删除成功！');
      store.actions.pageInfo()
    } else {
      msg.error(message);
    }
  },
  domainGrantData: async (store, id) => {
    const {code, context, message} = await getIpInfoDetail(id);
    if (code === "K-000000") {
      const domainGrant = context.domainGrantVO;
      store.setState({'domainGrantDataInfo': fromJS(domainGrant)});
    } else {
      msg.error(message);
    }
  },
  changeDomainGrantInfoData: async (store, value) => {
    const {domainGrantDataInfo} = store.state;
    store.setState({'domainGrantDataInfo': domainGrantDataInfo.set(value.key, value.value)});
  },
  addDomainGrant: async (store) => {
    const {domainGrantDataInfo, eventType} = store.state;
    const {code, message} = eventType === 1 ? await addDomainGrant(domainGrantDataInfo.toJS()) : await modifyIpWhiteInfo(domainGrantDataInfo.toJS());
    if (code === "K-000000") {
      msg.success('保存成功！');
      store.actions.modalHidden();
      store.actions.pageInfo();
    } else {
      msg.error(message);
    }
  },
  getCompanyInfoListData: async (store, param) => {
    const {code, context, message} = await companyInfoList(param);
    if (code === "K-000000") {
      store.setState({companyInfoListData: context.companyInfoVOList});
    } else {
      msg.error(message);
    }
  },
  getProductInfoList: async (store, param) => {
    const {code, context, message} = await productInfoList(param);
    if (code === "K-000000") {
      store.setState({productListData: context.productVOPage.content});
    } else {
      msg.error(message);
    }
  }
};

export default globalHook(React, initialState, actions);