import React from 'react';
import globalHook from 'use-global-hook';
import {fromJS} from 'immutable';
import {add, delProduct, getDetail, getPageInfo, modify} from './webApi'
import {message as msg} from "antd";

const initForm = fromJS({
  productName: '',
  pageNum: 0,
  pageSize: 10
})
const initialState = {
  searchForm: fromJS({
    productName: '',
    pageNum: 0,
    pageSize: 10
  }),
  pageDataInfo: fromJS({
    pageDataList: null,
    total: null,
    size: null,
    number: null
  }),
  visibleType: false,
  eventType: null,
  productDataInfo: fromJS({})
};

const actions = {
  resetForm: (store) => {
    store.setState({searchForm: initForm});
  },
  setSearchForm: (store, value) => {
    const {searchForm} = store.state;
    store.setState({searchForm: searchForm.set(value.key, value.value)});
  },
  pageInfo: async (store, value) => {
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    const productName = searchForm.get('productName');
    if (value && value.pageNum) {
      pageNum = value.pageNum
    }
    if (value && value.pageSize) {
      pageSize = value.pageSize
    }
    const {code, context, message} = await getPageInfo({
      'pageNum': pageNum,
      'pageSize': pageSize,
      'productName': productName
    });
    if (code === "K-000000") {
      const pageDataList = context.productVOPage.content;
      const total = context.productVOPage.total;
      const size = context.productVOPage.size;
      const number = context.productVOPage.number;
      const {pageDataInfo} = store.state;
      store.setState({
        pageDataInfo: pageDataInfo.set('pageDataList', pageDataList)
            .set('total', total)
            .set('size', size)
            .set('number', number)
      });
    } else {
      msg.error(message);
    }
  },
  onDelProduct: async (store, id) => {
    const {code, message} = await delProduct(id);
    if (code === "K-000000") {
      msg.success('删除成功！');
      store.actions.pageInfo()
    } else {
      msg.error(message);
    }
  },
  modalShow: async (store, eventType, id) => {
    store.actions.changeEventType(eventType);
    store.setState({'productDataInfo': fromJS({})});
    if (eventType === 2) {
      store.actions.productInfoData(id)
    }
    store.setState({visibleType: true});
  },
  modalHidden: async (store) => {
    store.setState({visibleType: false});
  },
  changeEventType: async (store, value) => {
    store.setState({eventType: value});
  },
  productInfoData: async (store, id) => {
    const {code, context, message} = await getDetail(id);
    if (code === "K-000000") {
      const productInfo = context.productVO;
      store.setState({'productDataInfo': fromJS(productInfo)});
    } else {
      msg.error(message);
    }
  },
  changeProductInfoData: async (store, value) => {
    const {productDataInfo} = store.state;
    store.setState({'productDataInfo': productDataInfo.set(value.get('key'), value.get('value'))});
  },
  addProductInfo: async (store) => {
    const {productDataInfo, eventType} = store.state;
    const {code, message} = eventType === 1 ? await add(productDataInfo.toJS()) : await modify(productDataInfo.toJS());
    if (code === "K-000000") {
      msg.success('保存成功！');
      store.actions.modalHidden();
      store.actions.pageInfo();
    } else {
      msg.error(message);
    }
  }
};

export default globalHook(React, initialState, actions);