import React from "react";
import { Form, Input, Button  } from 'antd';
const FormItem = Form.Item;


export default ({setSearchForm,setPageInfo,searchForm,setVisible}) => {
  return (
      <Form layout="inline"  style={{textAlign: 'left',paddingTop:10,paddingBottom:10}}>
          <FormItem label="客户编号">
              <Input
                  allowClear
                  placeholder="请输入客户编号"
                  value={searchForm.get('customerCode')}
                  onChange={(e) => setSearchForm({key: 'customerCode',value: e.target.value})}
              />
          </FormItem>
        <FormItem label="客户名称">
          <Input 
            allowClear
            placeholder="请输入客户名称"
            value={searchForm.get('customerName')}
            onChange={(e) => setSearchForm({key: 'customerName',value: e.target.value})}
            />
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => setPageInfo()}>查询</Button>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => setVisible()}>新增</Button>
        </FormItem>
      </Form>
  )
}


