import React from "react";
import { Form, Input, Button  } from 'antd';
import {fromJS} from "immutable";
const FormItem = Form.Item;


export default ({setSearchForm,searchClick,searchForm,modalShow}) => {
  return (
      <Form layout="inline"  style={{textAlign: 'left',paddingTop:10,paddingBottom:10}}>
        <FormItem label="项目名称">
          <Input
            placeholder="请输入项目名称"
            value={searchForm.get('name')}
            onChange={(e) => setSearchForm(fromJS({key:'name',value:e.target.value}))}
            />
        </FormItem>
        <FormItem label="ip地址">
          <Input
            placeholder="请输入ip地址"
            value={searchForm.get('ip')}
            onChange={(e) => setSearchForm(fromJS({key:'ip',value:e.target.value}))}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => searchClick()}>查询</Button>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => modalShow(1)}>新增</Button>
        </FormItem>
      </Form>
  )
}


