import fly from '../fly'

const getPageInfo = (param) => {
    return fly.post("/checklog/page", param)
}

const add = (param) => {
    return fly.post("/checklog/add", param)
}

const modify = (param) => {
    return fly.put("/checklog/modify", param)
}

const delCheckLog = (id) => {
    return fly.delete(`/checklog/${id}`, id)
}

const getDetail = (id) => {
    return fly.get(`/checklog/${id}`, id)
}

export {getPageInfo, add, modify, delCheckLog, getDetail}