import React from 'react';
import globalHook from 'use-global-hook';
import { login } from './webApi';
import { message as msg } from 'antd';

const initialState = {
  
};
 
const actions = {
  setToken: async(store, values) => {
    const {code,context} = await login(values);
    if(code === "K-000000"){
      if (context.token) {
        sessionStorage.setItem("token",`Bearer ${context.token}`); 
        sessionStorage.setItem("name", values.account);  
        window.location = "/";
      } else {
        msg.error('账号或密码不正确')
      }
    } else {
      msg.error('账号或密码不正确')
    }
  },
};

export default globalHook(React, initialState, actions);