import React from "react";
import {Table,Button} from 'antd';
import secondCheck from '../../seondCheck'

export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number');
  const columns = [{
    key: 'companyName',
    dataIndex: 'companyName',
    title: '项目名称'
    },
    {
      key: 'productName',
      dataIndex: 'productName',
      title: '产品名称'
    },
    {
      key: 'ip',
      dataIndex: 'ip',
      title: 'ip地址'
    },
    {
      key: 'environment',
      dataIndex: 'environment',
      title: '环境',
      render: (environment) => {
        let environmentValue = '无';
        if (environment === 0) {
          environmentValue = '开发';
        } else if (environment === 1) {
          environmentValue = '测试';
        } else if (environment === 2) {
          environmentValue = 'UAT';
        } else if (environment === 3) {
          environmentValue = '线上';
        }
        return environmentValue;
      }
    },
    {
      key: 'remark',
      dataIndex: 'remark',
      title: '备注'
    },
    {
      key: 'createTime',
      dataIndex: 'createTime',
      title: '创建时间'
    },
    {
      key: 'option',
      title: '操作',
      render: (rowInfo) => {return (
        <div>
            {/*<Button*/}
              {/*type="link"*/}
              {/*size="small"*/}
              {/*onClick={() => this._onEdit(rowInfo.id, false)}*/}
            {/*>*/}
              {/*详情*/}
            {/*</Button>*/}
            <Button
              type="link"
              size="small"
              onClick={() => props.modalShow(2,rowInfo.id)}
            >
              编辑
            </Button>
            <Button
              type="link" 
              size="small"
              onClick={() =>  secondCheck({okCallBack: props.onDelIp, okParam: rowInfo.id})}
            >
              删除
            </Button>
        </div>
      )}
    }];
  return (
    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={pageDataList}
      pagination={{
        total,
        pageSize,
        current: current,
        onChange: (pageNum, pageSize) => {
          props.changePageInfo({ pageNum: pageNum - 1, pageSize });
        }
      }}
    />
  )
})