import React from "react";
import { Form,Input, Select } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;


export default (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      span: 24,
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };
  return (
    <Form {...formItemLayout}>
      <FormItem label="公司信息">
        <Input value={props.checkLogDataInfo.companyInfo}/>
      </FormItem>
      <FormItem label="域名/mac地址">
        <Input value={props.checkLogDataInfo.domainMac}/>
      </FormItem>
      <FormItem label="授权状态">
        <Select
          // onSelect={(v) =>
          //   store.onChangeOrderInvoiceForm({
          //     propertyName: 'orderInvoiceType',
          //     propertyValue: v
          //   })
          // }
          defaultValue={props.checkLogDataInfo.status}
        >
          <Option value={0}>未授权</Option>
          <Option value={1}>授权</Option>
        </Select>
      </FormItem>
    </Form>
  )
}


