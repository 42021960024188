import React from 'react';
import globalHook from 'use-global-hook';
import {fromJS} from 'immutable';
import {page, deleteById, modify, add, getCustomerList} from './webApi'
import {message as msg} from 'antd';

const initForm = fromJS({
    mac: null,
    domain: null,
    environment: null,
    companyName: null,
    pageNum: 0,
    pageSize: 10
})

const initialState = {
    searchForm: initForm,
    pageInfo: fromJS({}),
    rowInfo:fromJS({}),
    visible: false,
    companyList: [],
    customerList: fromJS([])
};

const actions = {
    resetForm: (store) => {
        store.setState({searchForm: initForm});
    },
    setSearchForm: (store, {key, value}) => {
        const {searchForm} = store.state;
        store.setState({searchForm: searchForm.set(key, value)});
    },
    setPageInfo: async (store) => {
        const {searchForm} = store.state;
        const {context, code, message} = await page(searchForm);
        if (code === "K-000000") {
            const {companyInfoVOPage} = context;
            store.setState({pageInfo: fromJS(companyInfoVOPage)});
        } else {
            msg.error(message);
        }
    },
    deleteOperator: async (store, id) => {
        const {code, message} = await deleteById({id});
        if (code === "K-000000") {
            msg.success("删除成功！");
            store.actions.setPageInfo();
        } else {
            msg.error(message);
        }
    },
    setRowInfo: (store, rowInfo) => {
        store.setState({rowInfo: fromJS(rowInfo)});
    },
    setVisible: async (store, customerName) => {
        const {visible} = store.state;
        store.setState({visible: !visible})
        store.actions.getCustomerList();
    },
    saveOperator: async (store, values) => {
        const {rowInfo} = store.state;
        let code, message;
        if (rowInfo && rowInfo.get('id')) {
            const params = rowInfo.merge(fromJS(values)).toJS();
            const res = await modify(params);
            code = res.code;
            message = res.message;
        } else {
            const res = await add(values);
            code = res.code;
            message = res.message;
        }
        if (code === "K-000000") {
            msg.success("编辑成功！");
            store.actions.setPageInfo();
        } else {
            msg.error(message);
        }
    },

    getCustomerList: async (store, customerName) => {
        const res = await getCustomerList({'customerName': customerName});
        if (res.code === "K-000000") {
            store.setState({customerList: fromJS(res.context.customerVOPage.content)});
        }
    }
};


export default globalHook(React, initialState, actions);