import React from "react";
import {Table} from 'antd';


export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number');
  const columns = [{
    key: 'context',
    dataIndex: 'context',
    title: '推送内容'
    },{
    key: 'createTime',
    dataIndex: 'createTime',
    title: '推送时间'
  }];
  return (
    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={pageDataList}
      pagination={{
        total,
        pageSize,
        current: current,
        onChange: (pageNum, pageSize) => {
          props.changePageInfo({ pageNum: pageNum - 1, pageSize });
        }
      }}
    />
  )
})