import fly from '../fly'

export const getPageInfo = (param) =>{
  return fly.post("/ipWhiteQuery/page",param)
}

export const getIpInfoDetail = (param) =>{
  return fly.post("/ipWhiteQuery/getById",param)
}

export const addIpWhiteInfo = (param) =>{
  return fly.post("/ipWhite/add",param)
}

export const modifyIpWhiteInfo = (param) =>{
  return fly.post("/ipWhite/modify",param)
}

export const delIp = (param) =>{
  return fly.post("/ipWhite/deleteById",param)
}

export const getCompanyInfoListData = (param) => {
  return fly.post("/companyInfo/list",param)
}

export const getProductInfoListData = (param) => {
  return fly.post("/product/page", param)
}
