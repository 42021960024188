import fly from '../fly'


export const page = (params) => {
  return fly.post("/certGrantInfo/page",params)
}


export const deleteById = (params) => {
  return fly.post("/certGrantInfo/deleteById",params)
}


export const modify = (params) => {
  return fly.post("/certGrantInfo/modify",params)
}

export const add = (params) => {
  return fly.post("/certGrantInfo/add",params)
}

export const list = () => {
  return fly.post("/companyInfo/list",{})
}

export const productList = () => {
  return fly.post("/product/list",{})
}