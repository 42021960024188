import React, {Fragment, useEffect} from 'react';
import Breadcrumb from './components/breadcrumb';
import Search from './components/search';
import List from './components/list';
import ProductInfoModal from './components/product-info-modal'
import useGlobal from './store';

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const {pageInfo, resetForm} = globalActions;
    resetForm();
    pageInfo();
  }, [globalActions]);
  return (
      <Fragment>
        <Breadcrumb/>
        <Search
            setSearchForm={globalActions.setSearchForm}
            searchClick={globalActions.pageInfo}
            searchForm={globalState.searchForm}
            modalShow={globalActions.modalShow}
        />
        <List
            pageDataInfo={globalState.pageDataInfo}
            changePageInfo={globalActions.pageInfo}
            onDelProduct={globalActions.onDelProduct}
            modalShow={globalActions.modalShow}
        />
        <ProductInfoModal
            visibleType={globalState.visibleType}
            modalHidden={globalActions.modalHidden}
            eventType={globalState.eventType}
            productDataInfo={globalState.productDataInfo}
            changeProductInfoData={globalActions.changeProductInfoData}
            addProductInfo={globalActions.addProductInfo}
        />
      </Fragment>
  )
}
