import React from "react";
import {Button, Table} from 'antd';
import secondCheck from '../../seondCheck'

export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number') + 1;
  const columns = [
    // {
    //    key: 'number',
    //    dataIndex: 'number',
    //    title: '序号'
    //  },
    {
      key: 'companyInfo',
      dataIndex: 'companyInfo',
      title: '项目名称'
    },
    {
      key: 'customerName',
      dataIndex: 'customerName',
      title: '客户名称'
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '授权状态',
      render: (status) => {
        let statusValue = '无';
        if (status === 0) {
          statusValue = '未授权';
        } else {
          statusValue = '授权';
        }
        return statusValue;
      }
    },
    {
      key: 'domainMac',
      dataIndex: 'domainMac',
      title: '域名/mac地址'
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '校验类型',
      render: (type) => {
        let typeValue = '无';
        if (type === 0) {
          typeValue = '前端';
        } else if (type === 1) {
          typeValue = '后端';
        }
        return typeValue;
      }
    },
    {
      key: 'createTime',
      dataIndex: 'createTime',
      title: '创建时间'
    },
    {
      key: 'updateTime',
      dataIndex: 'updateTime',
      title: '最后授权时间'
    },
    {
      key: 'option',
      title: '操作',
      render: (rowInfo) => {
        return (
            <div>
              <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    secondCheck({
                      okCallBack: props.onDelCheckLog,
                      okParam: rowInfo.id
                    })
                  }}
              >删除</Button>
            </div>
        )
      }
    }];
  return (
      <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={pageDataList}
          pagination={{
            total,
            pageSize,
            current: current,
            onChange: (pageNum, pageSize) => {
              props.changePageInfo({pageNum: pageNum - 1, pageSize});
            }
          }}
      />
  )
})