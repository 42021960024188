import React from "react";
import {Form, Input, Button, Select} from 'antd';
import {fromJS} from "immutable";
const FormItem = Form.Item;
const Option = Select.Option;


export default ({setSearchForm,searchClick,searchForm}) => {
  return (
      <Form layout="inline"  style={{textAlign: 'left',paddingTop:10,paddingBottom:10}}>
        <FormItem label="推送内容">
          <Input
            placeholder="请输入推送内容"
            value={searchForm.get('name')}
            onChange={(e) => setSearchForm(fromJS({key:'name',value:e.target.value}))}
            />
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => searchClick()}>查询</Button>
        </FormItem>
      </Form>
  )
}


