import React from "react";
import {Button, Table} from 'antd';
import secondCheck from '../../seondCheck'

export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number') + 1;
  const columns = [
    {
      key: 'productName',
      dataIndex: 'productName',
      title: '产品名称'
    },
    {
      key: 'createTime',
      dataIndex: 'createTime',
      title: '创建时间'
    },
    {
      key: 'option',
      title: '操作',
      render: (rowInfo) => {
        return (
            <div>
              <Button
                  type="link"
                  size="small"
                  onClick={() => props.modalShow(2, rowInfo.id)}
                  style={{'marginRight': 10}}
              >
                编辑
              </Button>
              <Button type="link"
                      size="small"
                      onClick={() => secondCheck({okCallBack: props.onDelProduct, okParam: rowInfo.id})}>
                删除
              </Button>
            </div>
        )
      }
    }];
  return (
      <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={pageDataList}
          pagination={{
            total,
            pageSize,
            current: current,
            onChange: (pageNum, pageSize) => {
              props.changePageInfo({pageNum: pageNum - 1, pageSize});
            }
          }}
      />
  )
})