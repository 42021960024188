import React, { Fragment,useEffect }  from 'react';
import CertificateBreadcrumb from './components/certificate-breadcrumb';
import CertificateList from './components/certificate-list';
import CertificateSearch from './components/certificate-search';
import useGlobal from './store';
import CertificateModal from './components/certificate-modal'
export default () => {
  const [globalState, globalActions] = useGlobal();
  const { setPageInfo,setSearchForm,deleteOperator,setVisible,setRowInfo,saveOperator } = globalActions;
  const { pageInfo,searchForm,visible,rowInfo,companyList,productList } = globalState;
  useEffect(() => {
    globalActions.resetForm();
    globalActions.setPageInfo();
    globalActions.setCompanyList();
    globalActions.setProductList();
  },[globalActions])
  return (
    <Fragment>
      <CertificateBreadcrumb />
      <CertificateSearch 
        setSearchForm= {setSearchForm}
        setPageInfo = {setPageInfo}
        searchForm = {searchForm}
        setVisible= {setVisible}
      />
      <CertificateList 
        pageInfo={pageInfo} 
        searchForm= {searchForm}
        setPageInfo={setPageInfo}
        setSearchForm= {setSearchForm}
        deleteOperator={deleteOperator}
        setRowInfo={setRowInfo}
        setVisible= {setVisible}
      />
      <CertificateModal 
        setVisible= {setVisible}
        visible={visible}
        rowInfo={rowInfo}
        setRowInfo={setRowInfo}
        saveOperator={saveOperator}
        companyList={companyList}
        productList={productList}
      />
    </Fragment>
  )
}
