import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter,Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Login from "./login";
import { Form } from 'antd';
const LoginForm = Form.create({ name: 'login' })(Login);

ReactDOM.render(
<HashRouter>
<Route key="/login" exact={true} path= "/login" component={LoginForm}/>
  {sessionStorage.getItem("token") ? <App /> : <LoginForm />}
</HashRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
