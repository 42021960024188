import React from "react";
import {DatePicker, Form, Input, Modal} from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

const FormItem = Form.Item;

const customerForm = (props) => {
    const formItemLayout = {
        labelCol: {
            span: 2,
            xs: {span: 24},
            sm: {span: 6}
        },
        wrapperCol: {
            span: 24,
            xs: {span: 24},
            sm: {span: 14}
        }
    };
    const {getFieldDecorator} = props.form;
    const {rowInfo, setRowInfo, saveOperator} = props;
    return (
        <Modal
            maskClosable={false}
            title={rowInfo ? '编辑' : '新增'}
            width={600}
            visible={props.visible}
            cancelText="取消"
            okText="确定"
            onCancel={() => {
                props.form.resetFields();
                setRowInfo(null);
                props.setVisible();
            }}
            onOk={() => {
                props.form.validateFields(null, (errs, values) => {
                    if (!errs) {
                        saveOperator(values);
                        props.form.resetFields();
                        setRowInfo(null);
                        props.setVisible();
                    }
                });
            }}
        >
            <Form {...formItemLayout}>
                <FormItem label="客户名称" hasFeedback>
                    {getFieldDecorator('customerName', {
                        initialValue: rowInfo && rowInfo.get('customerName'),
                        rules: [
                            {required: true, message: '请输入客户名称'},
                            {min: 1, max: 100, message: '所在城市不超过100个字'},
                        ],
                    })(
                        <Input
                            placeholder="请输入客户名称"
                        />
                    )}
                </FormItem>
                <FormItem label="签约时间" hasFeedback>
                    {getFieldDecorator('contractDate', {
                        initialValue: rowInfo && moment(rowInfo.get('contractDate')),
                        rules: [{required: true, message: '请选择签约时间'}],
                    })(
                        <DatePicker locale={locale}  format={'YYYY-MM-DD'}/>
                    )}
                </FormItem>
                <FormItem label="所在城市" hasFeedback>
                    {getFieldDecorator('city', {
                        initialValue: rowInfo && rowInfo.get('city'),
                        rules: [
                            {required: true, message: '请输入所在城市'},
                            {min: 1, max: 100, message: '所在城市不超过100个字'},
                            {
                                pattern: /^[0-9a-zA-Z\u4E00-\u9FA5]*$/,
                                message: '不允许特殊字符',
                            },
                        ]
                    })(
                        <Input
                            placeholder="请输入所在城市"
                        />
                    )}
                </FormItem>
                <FormItem label="网站地址" hasFeedback>
                    {getFieldDecorator('webSiteAddress', {
                        initialValue: rowInfo && rowInfo.get('webSiteAddress'),
                        rules: [
                            {required: true, message: '请输入网站地址'},
                            {min: 5, max: 100, message: '网站地址不超过100个字'},
                            {
                                pattern: /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/,
                                message: '请输入正确的网站地址'
                            }
                        ]
                    })(
                        <Input
                            placeholder="请输入网站地址"
                        />
                    )}
                </FormItem>
            </Form>
        </Modal>
    )
}
const form = Form.create({name: 'customerForm'})(customerForm);
export default form;

