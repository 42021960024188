import React, { Fragment,useEffect }  from 'react';
import Breadcrumb from './components/breadcrumb';
import SettingForm from './components/settingForm'
import useGlobal from './store';

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const { getEmailConfigInfo } = globalActions;
    getEmailConfigInfo();
  }, [globalActions]);
  return (
    <Fragment>
      <Breadcrumb />
      <SettingForm
        warnEmailConfigData = {globalState.warnEmailConfigData}
        changeEmailConfigInfo = {globalActions.changeEmailConfigInfo}
        saveEmailConfigInfo = {globalActions.saveEmailConfigInfo}
      />
    </Fragment>
  )
}
