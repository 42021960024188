import React from 'react';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { getEmailConfigInfo, saveEmailConfigInfo } from './webApi'
import {message} from "antd";

const initialState = {
  searchForm: fromJS({
    name: '',
    pageNum:0,
    pageSize:10,
    ip:null
  }),
  warnEmailConfigData:fromJS({})
};
 
const actions = {
  getEmailConfigInfo:async (store)=>{
    const {code,context} = await getEmailConfigInfo();
    if(code==="K-000000"){
      store.setState({warnEmailConfigData: fromJS(context.warnEmailConfig)});
    }
  },
  changeEmailConfigInfo:async(store,value)=>{
    const {warnEmailConfigData} = store.state;
    store.setState({'warnEmailConfigData':warnEmailConfigData.set(value.get('key'),value.get('value'))});
  },
  saveEmailConfigInfo:async (store)=>{
    const {warnEmailConfigData} = store.state;
    const {code} = await saveEmailConfigInfo(warnEmailConfigData.toJS());
    if(code==="K-000000"){
      message.success('保存成功！');
    }
  }
};

export default globalHook(React, initialState, actions);