import React from 'react';
import { message } from 'antd';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { getPageInfo } from './webApi'

const initialState = {
  searchForm: fromJS({
    name: '',
    pageNum:0,
    pageSize:10,
    ip:null
  }),
  pageDataInfo:fromJS({
    pageDataList:null,
    total:null,
    size:null,
    number:null
  })
};
 
const actions = {
  setSearchForm: (store, value) => {
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set(value.get('key'),value.get('value'))});
  },
  pageInfo:async (store,value)=>{
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    let name = searchForm.get('name');
    if(value&&value.pageNum){
      pageNum = value.pageNum
    }
    if(value&&value.pageSize){
      pageSize = value.pageSize
    }
    const {code,context} = await getPageInfo({'pageNum':pageNum,'pageSize':pageSize,context:name});
    if(code==="K-000000"){
      const pageDataList = context.warnPushLogVOPage.content;
      const total = context.warnPushLogVOPage.total;
      const size = context.warnPushLogVOPage.size;
      const number = context.warnPushLogVOPage.number+1;
      const {pageDataInfo} = store.state;
      store.setState({pageDataInfo: pageDataInfo.set('pageDataList',pageDataList)
          .set('total',total)
          .set('size',size)
          .set('number',number)});
    } else {
      message.error('查询列表出错！');
    }
  }
};

export default globalHook(React, initialState, actions);