import React, { Fragment,useEffect }  from 'react';
import CustomerBreadcrumb from './components/customer-breadcrumb';
import CustomerList from './components/customer-list';
import CustomerSearch from './components/customer-search';
import useGlobal from './store';
import CustomerModal from './components/customer-modal'
export default () => {
  const [globalState, globalActions] = useGlobal();
  const { setPageInfo,setSearchForm,deleteOperator,setVisible,setRowInfo,saveOperator,resetForm } = globalActions;
  const { pageInfo,searchForm,visible,rowInfo } = globalState;
  useEffect(() => {
    resetForm();
    setPageInfo();
  },[setPageInfo,resetForm])
  return (
    <Fragment>
      <CustomerBreadcrumb />
      <CustomerSearch 
        setSearchForm= {setSearchForm}
        setPageInfo = {setPageInfo}
        searchForm = {searchForm}
        setVisible= {setVisible}
      />
      <CustomerList 
        pageInfo={pageInfo} 
        searchForm= {searchForm}
        setPageInfo={setPageInfo}
        setSearchForm= {setSearchForm}
        deleteOperator={deleteOperator}
        setRowInfo={setRowInfo}
        setVisible= {setVisible}
      />
      <CustomerModal 
        setVisible= {setVisible}
        visible={visible}
        rowInfo={rowInfo}
        setRowInfo={setRowInfo}
        saveOperator={saveOperator}
      />
    </Fragment>
  )
}
