import React from "react";
import { Table, Button  } from 'antd';
import secondCheck from '../../seondCheck'
export default ((props) => {
  const { pageInfo, setPageInfo,setSearchForm,deleteOperator,setRowInfo,setVisible } = props;
  const columns = [{
    key: 'customerName',
    dataIndex: 'customerName',
    title: '客户名称'
    },
    {
      key: 'customerCode',
      dataIndex: 'customerCode',
      title: '客户编号'
    },
    {
      key: 'contractDate',
      dataIndex: 'contractDate',
      title: '签约时间'
    },
    {
      key: 'city',
      dataIndex: 'city',
      title: '所在城市'
    },
    {
      key: 'webSiteAddress',
      dataIndex: 'webSiteAddress',
      title: '网站地址'
    },
    {
      key: 'option',
      title: '操作',
      render: (rowInfo) => {return (
        <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setRowInfo(rowInfo);
                setVisible();
              }}
            >
              编辑
            </Button>
        </div>
      )}
    }];
  return (
    <Table 
      rowKey={record => record.id}
      columns={columns} 
      dataSource={pageInfo && pageInfo.toJS().content}
      pagination={{
        pageSize: pageInfo && pageInfo.get('size'),
        total: pageInfo && pageInfo.get('totalElements'),
        current: pageInfo && pageInfo.get('number') + 1,
        onChange: (currentPage) => {
          setSearchForm({key: 'pageNum',value: currentPage -1 });
          setPageInfo();
        }
      }}
    />
  )
})