import fly from '../fly'

export const getEmailConfigInfo = (param) =>{
  return fly.post("/emailConfig/find",param)
}

export const saveEmailConfigInfo = (param) =>{
  return fly.post("/emailConfig/save",param)
}

