import fly from '../fly'

const getPageInfo = (param) => {
  return fly.post("/product/page", param)
}

const add = (param) => {
  return fly.post("/product/add", param)
}

const modify = (param) => {
  return fly.put("/product/modify", param)
}

const delProduct = (id) => {
  return fly.delete(`/product/${id}`, id)
}

const getDetail = (id) => {
  return fly.get(`/product/${id}`, id)
}

export {getPageInfo, add, modify, delProduct, getDetail}