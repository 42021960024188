import React from "react";
import {Form, Input, Modal, Select} from 'antd';
import {fromJS} from "immutable";

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;

const ipForm = (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      span: 24,
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };
  const { getFieldDecorator } = props.form;
  const {visibleType,modalHidden,eventType,ipWhiteDataInfo,changeIpInfoData,addIpWhiteInfo,companyInfoListData,getCompanyInfoListData,productDataList,getProductDataList} = props;
  return (
    <Modal  maskClosable={false}
            title={eventType===1?'新增':"编辑"}
            width={600}
            visible={visibleType}
            okText = '确定'
            cancelText = '取消'
            onCancel={() => {
                props.form.resetFields();
                modalHidden()
              }
            }
            onOk={()=>{
                props.form.validateFields(null, (errs) => {
                  if (!errs) {
                    addIpWhiteInfo()
                    props.form.resetFields();
                  }
                });
              }
            }
    >
      <Form {...formItemLayout}>
        <FormItem label="项目名称" hasFeedback>
          {getFieldDecorator('companyName', {
            initialValue: ipWhiteDataInfo.get('companyName'),
            rules: [{ required: true, message: '请选择公司' }],
          })(
            <Select placeholder="请选择公司"
                    showSearch
                    filterOption = {(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}
                    onSelect={(v) =>{
                      changeIpInfoData(fromJS({
                        'key': 'companyName',
                        'value': v
                      }))
                    }
                    }
            >
              {companyInfoListData.map(company =>
                <Option key={company.id} value={company.companyName}>{company.companyName}</Option>
              )}
            </Select>
          )}
        </FormItem>
        <FormItem label="产品名称" hasFeedback>
          {getFieldDecorator('productName', {
            initialValue:ipWhiteDataInfo.get('productName'),
            rules: [{ required: true, message: '请输入产品名称' }],
          })(
            <Select placeholder="请选择产品名称"
                    showSearch
                    onSearch={(value) => {
                      getProductDataList(value);
                    }}
                    onSelect={(v) =>{
                      changeIpInfoData(fromJS({
                        'key': 'productName',
                        'value': v
                      }))
                    }
                    }
            >
              {productDataList.map(product =>
                <Option key={product.id} value={product.productName}>{product.productName}</Option>
              )}
            </Select>
          )}
        </FormItem >
        <FormItem label="IP地址" hasFeedback>
          {getFieldDecorator('ip', {
            initialValue: ipWhiteDataInfo.get('ip'),
            rules: [{ required: true, message: '请输入IP地址' }],
          })(
            <Input
                   onChange={(e)=>{changeIpInfoData(fromJS({'key':'ip','value':e.target.value}))}}
            />
          )}
        </FormItem>
        <FormItem label="环境" hasFeedback>
          {getFieldDecorator('environment', {
            initialValue:ipWhiteDataInfo.get('environment'),
            rules: [{ required: true, message: '请选择环境' }],
          })(
            <Select
              onSelect={(v) =>{
                  changeIpInfoData(fromJS({
                    'key': 'environment',
                    'value': v
                  }))
                }
              }
            >
              <Option value={0}>开发</Option>
              <Option value={1}>测试</Option>
              <Option value={2}>UAT</Option>
              <Option value={3}>线上</Option>
            </Select>
          )}
        </FormItem>
        <FormItem label="备注" hasFeedback>
          {getFieldDecorator('remark', {
            initialValue: ipWhiteDataInfo.get('remark'),
            rules: [{ required: true, message: '请输入备注' }],
          })(
            <TextArea value={ipWhiteDataInfo.get('remark')}
                      onChange={(e)=>{changeIpInfoData(fromJS({'key':'remark','value':e.target.value}))}}
            />
          )}
        </FormItem>
      </Form>
    </Modal>
  )
}

const form = Form.create({ name: 'ipForm' })(ipForm);
export default form;


