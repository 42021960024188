import CustomerManagement from './customer-management';
import IpWhite from './ip-white';
import CertificateManagement from './certificate-management';
import CheckLog from './check-log'
import Product from './product'
import WarnList from './warn-list'
import DomainGrant from './domain-grant'
import WarnPushList from './warn-push-list'
import WarnPushSetting from './warn-push-setting'
import OperateLog from './operate-log'
import ProjectManagement from './project-management';

const routes = [{
    path: '/',
    exact: true,
    component: Product
  },
  {
    path: '/product-management',
    exact: false,
    icon: 'cloud',
    desc: '产品列表',
    component: Product
  },
  {
    path: '/customer-management',
    exact: false,
    icon: 'user',
    desc: '客户列表',
    component: CustomerManagement
  },
  {
    path: '/project-management',
    exact: false,
    icon: 'project',
    desc: '项目列表',
    component: ProjectManagement
  },
  {
    path: '/ip-white',
    exact: false,
    icon: 'file-protect',
    desc: 'IP白名单',
    component: IpWhite
  },
  {
    path: '/certificate-management',
    exact: false,
    icon: 'book',
    desc: '证书列表',
    component: CertificateManagement
  },
  {
    path: '/domain-white',
    exact: false,
    icon: 'file-sync',
    desc: '域名白名单',
    component: DomainGrant
  },
  {
    path: '/check-log',
    exact: false,
    icon: 'exception',
    desc: '校验日志',
    component: CheckLog
  },
  {
    path: '/warn-list',
    exact: false,
    icon: 'exclamation-circle',
    desc: '告警记录',
    component: WarnList
  },
  {
    path: '/war-push-setting',
    exact: false,
    icon: 'message',
    desc: '推送配置',
    component: WarnPushSetting
  },
  {
    path: '/warn-push-list',
    exact: false,
    icon: 'mail',
    desc: '推送记录',
    component: WarnPushList
  },
  {
    path: '/operate-log',
    exact: false,
    icon: 'exception',
    desc: '操作日志',
    component: OperateLog
  },
]
export default routes