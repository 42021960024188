import React from "react";
import {Button, Form, Input, Select} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;

export default ({setSearchForm, searchClick, searchForm}) => {
  return (
      <Form layout="inline" style={{textAlign: 'left', paddingTop: 10, paddingBottom: 10}}>
        <FormItem label="项目名称">
          <Input
              placeholder="请输入项目名称"
              value={searchForm.get('companyInfo')}
              onChange={(e) => setSearchForm({key: "companyInfo", value: e.target.value})}
          />
        </FormItem>
          <FormItem label="客户名称">
              <Input
                  placeholder="请输入客户名称"
                  value={searchForm.get('customerName')}
                  onChange={(e) => setSearchForm({key: "customerName", value: e.target.value})}
              />
          </FormItem>
        <FormItem label="域名/mac地址">
          <Input
              placeholder="请输入域名/mac地址"
              value={searchForm.get('domainMac')}
              onChange={(e) => setSearchForm({key: "domainMac", value: e.target.value})}
          />
        </FormItem>
        <FormItem label="授权状态">
          <Select
              value={searchForm.get('status')}
              onChange={(value) => {
                setSearchForm({key: "status", value: value});
              }}
              style={{width: 80}}
          >
            <Option key="-1" value={null}>所有</Option>
            <Option key="0" value={0}>未授权</Option>
            <Option key="1" value={1}>授权</Option>
          </Select>
        </FormItem>
        <FormItem label="校验类型">
          <Select
              value={searchForm.get('type')}
              onChange={(value) => {
                setSearchForm({key: "type", value: value});
              }}
          >
            <Option value={null}>所有</Option>
            <Option value={0}>前端</Option>
            <Option value={1}>后端</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => searchClick()}>查询</Button>
        </FormItem>
      </Form>
  )
}


