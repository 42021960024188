import React from 'react';
import { Modal,Input,Icon, message as msg} from 'antd';
import fly from './fly'
const secondCheck = ({okCallBack,okParam,cancelCallBack}) => {
  const modal = Modal.confirm();
  const onOk = async() => {
    const secondPwd = document.getElementById('secondPwd').value;
    if (secondPwd) {
      const {code, message,context} = await checkOperate({checkOperatePwd: secondPwd})
      if (code === 'K-000000') {
        const {checkOperate} = context;
        if (checkOperate === true) {
          if(okCallBack) {
            okCallBack(okParam);
          }
          modal.destroy();
        } else {
          msg.error("密码错误，请重新再试！");
          return Promise.reject('密码错误，请重新再试！')
        }
      } else {
        msg.error(message);
        return Promise.reject('密码错误，请重新再试！')
      }
    } else {
      msg.error('超管密码不能为空！')
      return Promise.reject('超管密码不能为空！')
    }
  }
  modal.update({
    title: '请输入超管密码',
    content: <Input prefix={<Icon type="lock" style={{ fontSize: 13 }} />} type="password" placeholder="密码" id="secondPwd" onPressEnter={onOk}/>,
    cancelText: '取消',
    okText: '确定',
    onOk: onOk,
    onCancel: () => {
      if (cancelCallBack) {
        cancelCallBack();
      }
    }
  });
}

const checkOperate = (param) => {
  return fly.post("/operator/checkOperate",param)
}

export default secondCheck;