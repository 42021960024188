import fly from '../fly'


export const page = (params) => {
  return fly.post("/customer/page",params)
}


export const deleteById = (params) => {
  return fly.post("/customer/deleteById",params)
}


export const modify = (params) => {
  return fly.put("/customer/modify",params)
}

export const add = (params) => {
  return fly.post("/customer/add",params)
}
