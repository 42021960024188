import React from "react";
import { Form,Input, Select,Modal,DatePicker, Radio } from 'antd';
import secondCheck from '../../seondCheck'
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const certificateForm = (props) => {
  const formItemLayout = {
    labelCol: {
      span: 2,
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      span: 24,
      xs: { span: 24 },
      sm: { span: 14 }
    }
  };
  const { getFieldDecorator } = props.form;
  const { rowInfo,setRowInfo,saveOperator,companyList,productList } = props;
  return (
    <Modal 
      maskClosable={false}
      title={rowInfo?'编辑':'新增'}
      width={600}
      visible={props.visible}
      cancelText="取消"
      okText="生成证书"
      onCancel={() => {
        props.form.resetFields();
        setRowInfo(null);
        props.setVisible();
      }}
      okButtonProps={{
        htmlType: 'submit'
      }}
      onOk={() => {
        props.form.validateFields(null, (errs,values) => {
          if (!errs) {
            const fun = () => {
              const { rangeDate } = values;
              values.startDate = moment(rangeDate[0]).format('YYYY-MM-DD');
              values.endDate = moment(rangeDate[1]).format('YYYY-MM-DD')
              saveOperator(values);
              props.form.resetFields();
              setRowInfo(null);
              props.setVisible();
            }
            secondCheck({okCallBack: fun})
          }
        });
      }}
    >
    <Form {...formItemLayout}>
      <FormItem label="项目名称" hasFeedback>
      {getFieldDecorator('companyId', {
            initialValue: rowInfo && rowInfo.get('companyId'),
            rules: [{ required: true, message: '请选择项目名称' }],
          })(
        <Select placeholder="请选择项目名称" showSearch
        filterOption = {(inputValue, option) => option.props.children.indexOf(inputValue) !== -1}>
          {companyList.map(company => 
            <Option key={company.id} value={company.id}>{company.companyName}</Option>
          )}
        </Select>
      )}
      </FormItem>
      <FormItem label="产品" hasFeedback>
      {getFieldDecorator('productName', {
            initialValue: rowInfo && rowInfo.get('productName'),
            rules: [{ required: true, message: '请选择产品' }],
          })(
        <Select placeholder="请选择产品" showSearch>
          {productList.map(product => 
            <Option key={product.id} value={product.productName}>{product.productName}</Option>
          )}
        </Select>
      )}
      </FormItem>
      <FormItem label="mac地址" hasFeedback>
      {getFieldDecorator('mac', {
            initialValue: rowInfo && rowInfo.get('mac'),
            rules: [{ required: true, message: '请输入mac地址' }],
          })(
            <Input
              placeholder="请输入mac地址，多个用英文逗号隔开"
            />
      )}
      </FormItem>
      <FormItem label="域名" hasFeedback>
      {getFieldDecorator('domain', {
            initialValue: rowInfo && rowInfo.get('domain'),
            rules: [{ required: true, message: '请输入域名' }],
          })(
            <Input
              placeholder="请输入域名"
            />
      )}
      </FormItem>
      <FormItem label="环境" hasFeedback>
      {getFieldDecorator('environment', {
            initialValue: rowInfo && rowInfo.get('environment'),
            rules: [{ required: true, message: '请选择环境' }],
          })(
        <Select placeholder="请选择环境">
          <Option value={0}>开发</Option>
          <Option value={1}>测试</Option>
          <Option value={2}>UAT</Option>
          <Option value={3}>线上</Option>
        </Select>
      )}
      </FormItem>
      <FormItem label="生效时间" hasFeedback>
      {getFieldDecorator('rangeDate', {
            initialValue: rowInfo && [moment(rowInfo.get('startDate')),moment(rowInfo.get('endDate'))],
            rules: [{ required: true, message: '请选择生效时间' }],
          })(
          <RangePicker locale={locale} />
      )}
      </FormItem>
      <FormItem label="证书到期时间验证">
        {getFieldDecorator('effectType', {
          initialValue: rowInfo && rowInfo.get('effectType') || 0,
          rules: [{ required: true, message: '请选择生效设置' }],
        })(
            <RadioGroup>
              <Radio value={0}>证书生效</Radio>
              <Radio value={1}>设置生效</Radio>
            </RadioGroup>
        )}
      </FormItem>
      <FormItem label="备注" hasFeedback>
        {getFieldDecorator('remark', {
          initialValue: rowInfo && rowInfo.get('remark'),
        })(
            <TextArea
                placeholder="请输入备注"
            />
        )}
      </FormItem>
    </Form>
    </Modal>
  )
}
const form = Form.create({ name: 'certificateForm' })(certificateForm);
export default form;

