import fly from '../fly'

const getPageInfo = (param) => {
  return fly.post("/domaingrant/page", param)
}

const getIpInfoDetail = (id) => {
  return fly.get(`/domaingrant/${id}`, id)
}

const addDomainGrant = (param) => {
  return fly.post("/domaingrant/add", param)
}

const modifyIpWhiteInfo = (param) => {
  return fly.put("/domaingrant/modify", param)
}

const delIp = (id) => {
  return fly.delete(`/domaingrant/${id}`, id)
}

const companyInfoList = (param) => {
  return fly.post("/companyInfo/list",param)
}

const productInfoList = (param) => {
  return fly.post("/product/page", param)
}

export {getPageInfo, addDomainGrant, modifyIpWhiteInfo, delIp, getIpInfoDetail, companyInfoList, productInfoList}