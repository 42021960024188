import React from 'react';
import globalHook from 'use-global-hook';
import {fromJS} from 'immutable';
import {delCheckLog, getDetail, getPageInfo} from './webApi'
import {message as msg} from "antd";

const initForm = fromJS({
  companyInfo: '',
  domainMac: '',
  status: null,
  type: null,
  pageNum: 0,
  pageSize: 10
})
const initialState = {
  searchForm: fromJS({
    pageNum: 0,
    pageSize: 10,
    companyInfo: '',
    domainMac: '',
    status: null,
    type: null,
    customerName: ''
  }),
  pageDataInfo: fromJS({
    pageDataList: null,
    total: null,
    size: null,
    number: null
  }),
  visibleType: false,
  eventType: null,
  checkLogDataInfo: {}
};

const actions = {
  resetForm: (store) => {
    store.setState({searchForm: initForm});
  },
  setSearchForm: (store, value) => {
    const {searchForm} = store.state;
    store.setState({searchForm: searchForm.set(value.key, value.value)});
  },
  pageInfo: async (store, value) => {
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    if (value && value.pageNum) {
      pageNum = value.pageNum
    }
    if (value && value.pageSize) {
      pageSize = value.pageSize
    }
    const companyInfo = searchForm.get('companyInfo');
    const domainMac = searchForm.get('domainMac');
    const status = searchForm.get('status');
    const type = searchForm.get('type');
    const customerName = searchForm.get('customerName');
    const {code, context, message} = await getPageInfo({
      'pageNum': pageNum,
      'pageSize': pageSize,
      'companyInfo': companyInfo,
      'domainMac': domainMac,
      'status': status,
      'type': type,
      'customerName': customerName
    });
    if (code === "K-000000") {
      const pageDataList = context.checkLogVOPage.content;
      const total = context.checkLogVOPage.total;
      const size = context.checkLogVOPage.size;
      const number = context.checkLogVOPage.number;
      const {pageDataInfo} = store.state;
      store.setState({
        pageDataInfo: pageDataInfo.set('pageDataList', pageDataList)
            .set('total', total)
            .set('size', size)
            .set('number', number)
      });
    } else {
        msg.error(message);
    }
  },
  addIpInfo: async (store) => {

  },
  onDelCheckLog: async (store, id) => {
    const {code, message} = await delCheckLog(id);
    if (code === "K-000000") {
      msg.success('删除成功！');
      store.actions.pageInfo()
    } else {
        msg.error(message);
    }
  },
  modalShow: async (store, eventType, id) => {
    store.actions.changeEventType(eventType);
    if (eventType === 2) {
      store.actions.checkLogInfoData(id)
    } else {
      store.setState({'checkLogDataInfo': {}});
    }
    store.setState({visibleType: true});
  },
  modalHidden: async (store) => {
    store.setState({visibleType: false});
  },
  changeEventType: async (store, value) => {
    store.setState({eventType: value});
  },
  checkLogInfoData: async (store, id) => {
    const {code, context, message} = await getDetail(id);
    if (code === "K-000000") {
      const checkLogInfo = context.checkLogVO;
      store.setState({'checkLogDataInfo': checkLogInfo});
    } else {
        msg.error(message);
    }
  }
};

export default globalHook(React, initialState, actions);