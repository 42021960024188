import React from "react";
import { Form, Input, Button, Select} from 'antd';
const FormItem = Form.Item;
const {Option} = Select;

export default ({setSearchForm,setPageInfo,searchForm,setVisible}) => {
  return (
      <Form layout="inline"  style={{textAlign: 'left',paddingTop:10,paddingBottom:10}}>
        <FormItem label="项目名称">
          <Input 
            allowClear
            placeholder="请输入项目名称"
            value={searchForm.get('companyName')}
            onChange={(e) => setSearchForm({key: 'companyName',value: e.target.value})}
            />
        </FormItem>
        <FormItem label="mac地址">
          <Input 
            allowClear
            placeholder="请输入mac地址" 
            value={searchForm.get('mac')}
            onChange={(e) => setSearchForm({key: 'mac',value: e.target.value})}
            />
        </FormItem>
        <FormItem label="域名">
          <Input 
            allowClear
            placeholder="请输入域名" 
            value={searchForm.get('domain')}
            onChange={(e) => setSearchForm({key: 'domain',value: e.target.value})}
            />
        </FormItem>
        <FormItem label="环境">
          <Select placeholder="请选择环境" value={searchForm.get('environment')}
            onChange={(e) => setSearchForm({key: 'environment',value: e})}
          >
            <Option value={null}>全部</Option>
            <Option value={0}>开发</Option>
            <Option value={1}>测试</Option>
            <Option value={2}>UAT</Option>
            <Option value={3}>线上</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => setPageInfo()}>查询</Button>
        </FormItem>
        <FormItem>
          <Button type="primary" onClick={() => setVisible()}>新增</Button>
        </FormItem>
      </Form>
  )
}


