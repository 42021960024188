import React from "react";
import {  Menu, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import routers from "../router";

const nav = (props) => {
  return (
      <Menu theme="light" mode="inline" defaultSelectedKeys={[props.location.pathname === "/" ? "/product-management" : props.location.pathname]}
        onSelect={(keyObj)=> 
            props.history.push(keyObj.key)
        }
      >
        {
          routers.filter(router => router.path !== '/').map(router =>  
            <Menu.Item key={router.path}>
              <Icon type={router.icon}/>
              <span className="nav-text">{router.desc}</span>
            </Menu.Item>
          )
        }
      </Menu>
  )
}

export default withRouter(nav);