import React, { Fragment,useEffect }  from 'react';
import Breadcrumb from './components/breadcrumb';
import Search from './components/search';
import List from './components/list';
import useGlobal from './store';

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const { pageInfo,resetSearchForm } = globalActions;
    resetSearchForm();
    pageInfo();
  }, [globalActions]);
  return (
    <Fragment>
      <Breadcrumb />
      <Search
        setSearchForm= {globalActions.setSearchForm}
        searchClick= {globalActions.pageInfo}
        searchForm = {globalState.searchForm}
      />
      <List
        pageDataInfo = {globalState.pageDataInfo}
        changePageInfo= {globalActions.pageInfo}
       />
    </Fragment>
  )
}
