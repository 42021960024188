import React from 'react';
import { message } from 'antd';
import globalHook from 'use-global-hook';
import { fromJS } from 'immutable';
import { getPageInfo } from './webApi'

const initialState = {
  searchForm: fromJS({
    name: '',
    pageNum:0,
    pageSize:10,
    ip:null
  }),
  pageDataInfo:fromJS({
    pageDataList:null,
    total:null,
    size:null,
    number:null
  })
};
 
const actions = {
  setSearchForm: (store, value) => {
    const pageNum = value.pageNum;
    const pageSize = value.pageSize;
    const ip = value.ip;
    const name = value.name;
    const { searchForm } = store.state;
    store.setState({ searchForm: searchForm.set('name',name)
        .set('pageNum',pageNum)
        .set('pageSize',pageSize)
        .set('ip',ip)});
  },
  pageInfo:async (store,value)=>{
    const {searchForm} = store.state;
    let pageNum = searchForm.get('pageNum');
    let pageSize = searchForm.get('pageSize');
    if(value&&value.pageNum){
      pageNum = value.pageNum
    }
    if(value&&value.pageSize){
      pageSize = value.pageSize
    }
    const {code,context} = await getPageInfo({'pageNum':pageNum,'pageSize':pageSize});
    if(code==="K-000000"){
      const pageDataList = context.operationLogVOPage.content;
      const total = context.operationLogVOPage.total;
      const size = context.operationLogVOPage.size;
      const number = context.operationLogVOPage.number+1;
      const {pageDataInfo} = store.state;
      store.setState({pageDataInfo: pageDataInfo.set('pageDataList',pageDataList)
          .set('total',total)
          .set('size',size)
          .set('number',number)});
    } else {
      message.error('查询列表出错！');
    }
  }
};

export default globalHook(React, initialState, actions);