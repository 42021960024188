import React, { Fragment,useEffect }  from 'react';
import Breadcrumb from './components/breadcrumb';
import Search from './components/search';
import List from './components/list';
import IpInfoModal from './components/ip-info-modal'
import useGlobal from './store';

export default () => {
  const [globalState, globalActions] = useGlobal();
  useEffect(() => {
    const { pageInfo,getCompanyInfoListData, resetSearchForm,getProductDataList } = globalActions;
    resetSearchForm();
    getCompanyInfoListData();
    pageInfo();
    getProductDataList();
  }, [globalActions]);
  return (
    <Fragment>
      <Breadcrumb />
      <Search
        setSearchForm= {globalActions.setSearchForm}
        searchClick= {globalActions.pageInfo}
        searchForm = {globalState.searchForm}
        modalShow = {globalActions.modalShow}
      />
      <List
        pageDataInfo = {globalState.pageDataInfo}
        changePageInfo= {globalActions.pageInfo}
        onDelIp = {globalActions.onDelIp}
        modalShow = {globalActions.modalShow}
       />
      <IpInfoModal
        visibleType = {globalState.visibleType}
        modalHidden = {globalActions.modalHidden}
        eventType = {globalState.eventType}
        ipWhiteDataInfo = {globalState.ipWhiteDataInfo}
        changeIpInfoData = {globalActions.changeIpInfoData}
        addIpWhiteInfo = {globalActions.addIpWhiteInfo}
        companyInfoListData = {globalState.companyInfoListData}
        getCompanyInfoListData = {globalActions.getCompanyInfoListData}
        productDataList = {globalState.productDataList}
        getProductDataList = {globalActions.getProductDataList}
      />
    </Fragment>
  )
}
