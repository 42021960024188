import React from "react";
import {Table} from 'antd';


export default ((props) => {
  const pageDataList = props.pageDataInfo.get('pageDataList');
  const total = props.pageDataInfo.get('total');
  const pageSize = props.pageDataInfo.get('size');
  const current = props.pageDataInfo.get('number');
  const columns = [
    {
      key: 'companyInfo',
      dataIndex: 'companyInfo',
      title: '项目名称'
    },
    {
    key: 'domainMac',
    dataIndex: 'domainMac',
    title: '域名/mac地址'
    },
    {
      key: 'warnType',
      dataIndex: 'warnType',
      title: '告警类型',
      render: (warnType) => {
        let warnTypeValue = '无';
        if (warnType === 0) {
          warnTypeValue = '非法授权告警';
        } else if (warnType === 1) {
          warnTypeValue = '证书不在有效期内告警';
        }
        return warnTypeValue;
      }
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '非法授权类型',
      render: (type) => {
        let typeValue = '无';
        if (type === 0) {
          typeValue = '前端';
        } else if (type === 1) {
          typeValue = '后端';
        }
        return typeValue;
      }
    },
    {
      key: 'firstTime',
      dataIndex: 'firstTime',
      title: '首次告警时间'
    },
    {
      key: 'lastTime',
      dataIndex: 'lastTime',
      title: '最后告警时间'
    },
    {
      key: 'warnNum',
      dataIndex: 'warnNum',
      title: '告警次数'
    }];
  return (
    <Table
      rowKey={record => record.id}
      columns={columns}
      dataSource={pageDataList}
      pagination={{
        total,
        pageSize,
        current: current,
        onChange: (pageNum, pageSize) => {
          props.changePageInfo({ pageNum: pageNum - 1, pageSize });
        }
      }}
    />
  )
})